<div class="container-fluid">
    <h3>Filters</h3>
    <div class="filters">
        
      
        <label for="supplier">Filter</label>
        <input type="text" class="dropdown"/>
        <label for="category">Page Size</label>
        
        <select id="category" class="dropdown">
            <!-- Add options here -->
        </select>
        <button id="addButton" class="btn btn-primary add-button" (click)="add()">Add +</button>
    </div>
    <div class="scroll">
        <table
    class="custom-table">
    <thead>
      <tr>
        <th>Product Name</th>
        <th>Status</th>
        <th>Action</th>
        
        </tr>
        </thead>
        <tbody >
            <tr *ngFor="
            let element of categoryList
            | paginate: { itemsPerPage: 25, currentPage: page }
          "
            >
                
              <td>{{element.productName}}</td>  
              <td>
                <div class="example-button-container">
                  <mat-icon
            class="status-icon"
            style="width: 30%;"
            [style.color]="element.isActive ? 'green' : 'red'"
            (click)="toggleStatus(element)"
          >
            {{ element.isActive ? 'toggle_on' : 'toggle_off' }}
          </mat-icon>
                </div>
              </td>  
              
              <td>
              
                <mat-icon (click)="onEdit(element.id)">edit</mat-icon>
                <mat-icon (click)="onDelete(element.id)" >delete</mat-icon>
               
              </td>
             
            </tr>
            </tbody>
            </table>
     </div>
</div>