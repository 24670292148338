
<div class="form-container">
    <button class="back-button" (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
    </button>
    <h2>New Category</h2>
    <form [formGroup]="categoryForm">
      <div class="form-row">
        <div class="form-group col-sm-12 col-md-12 col-lg-4">
          <label for="productName">Product Name<span class="requried-field">*</span></label>
          <input
            type="text"
            id="productName"
            placeholder="Enter productName"
            class="form-control"
            formControlName="productName"
            [ngClass]="{ 'is-invalid': submitted && f['productName'].errors }"
          />
          <div *ngIf="submitted && f['productName'].errors" class="invalid-feedback">
            <div *ngIf="f['productName'].errors['required']">Category Name is required</div>
          </div>
        </div>
        </div>
       

      
  
      <div class="formbutton">

     
        <button type="submit" class="btn btn-primary" (click)="onSubmit(categoryForm.value)">Submit</button>
        <button type="submit" class="btn btn-danger btn2"  (click)="onCancel()">Cancel</button>
      </div>
    </form>
  </div>
  