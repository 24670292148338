import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../../services/notificationService';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DataService } from '../data.service';
import moment from 'moment';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrl: './edit.component.scss'
})
export class EditComponent {
  siteStatusData: string[] = ['On-going','Started','Completed','Stopped',];
  states: string[] = ['State 1', 'State 2', 'State 3'];
  categoryForm: FormGroup = new FormGroup({});
  categoryData:any[]=[];
  categoryDetails:any[]=[];
  submitted: boolean = false;
  _id:any

  constructor(private data: DataService,
    private formBuilder: FormBuilder,
    private toastr:NotificationService,
    private router:Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit()  {
    if (this.route.snapshot.queryParamMap.get("id")) {
      // @ts-ignore TS2322
      this._id = this.route.snapshot.queryParamMap.get("id");
      this.getById(this._id);
    }

    this.categoryForm= this.formBuilder.group({
      productName: ['', Validators.required], 
    });
 
  }

  getById(_id:any){
    this.data.getById(_id).subscribe((res:any)=>{
      console.log(res,"res")
     if(res){
      //console.log(this.categoryData,"concel")
      this.updateView(res)
     }
    })
    
  }
  updateView(data:any){
    console.log(data,"Edit")
    this.categoryForm.patchValue({
      productName:data.productName
    })
  }
  goBack() {
    this.router.navigate(['/dashboard/category']);
  }
 
  get f() {
    return this.categoryForm.controls;
  }

  onSubmit(data: any) {
    data.updateOn=moment().format();

    this.data.update(data,this._id).subscribe((res:any)=>{
      console.log('category Updated!',res);
      this.toastr.showSuccess("Successfully!!","category Updated")
      this.router.navigate(['/dashboard/category'])
    })
  }
  onCancel(){
    this.categoryForm.reset()
  }
}
